import * as React from "react"
import { useLocation } from "react-router-dom"
import { Button, ButtonLink } from "@allied/react-web/Button"
import { Card } from "@allied/react-web/Card"
import { Carousel2 } from "@allied/react-web/Carousel"
import { Container } from "@allied/react-web/Container"
import { Field, FieldNotif } from "@allied/react-web/Field"
import { InputText, InputPhone, InputEmail } from "@allied/react-web/Input"
import { Header } from "@allied/react-web/Header"
import { MapViewer } from "@allied/react-web/MapViewer"
import { Meta } from "@allied/react-web/Meta"
import { Modal } from "@allied/react-web/Modal"
import { Image } from "@allied/react-web/Image"
import { Loader } from "@allied/react-web/Loader"
import { Link } from "@allied/react-web/Router"
import { Textarea } from "@allied/react-web/Textarea"
import { useValidator, Validator, useAutoPosition, useHashFragment, useRecaptcha } from "@allied/react-web/hooks"
import { toast } from "../components/Toast"
import { ContactService } from "../services/api/Contact"

type ServiceItem = {
  name: string
  description: string
  image: string
}

type TeamItem = {
  name: string
  occupation: string
  medias: MediaItem[]
}

type MediaItem = {
  category: string
  items: LinkItem[]
}

type LinkItem = {
  to: string
  content: string
}

const contactService = new ContactService({
  host: process.env.REACT_APP_API_HOST
})

export default function Home(): React.JSX.Element {
  const [modalTeam, setModalTeam] = React.useState({
    opened: false,
  })
  const [teams] = React.useState<TeamItem[]>([
    {
      name: "Mr. Soe Thu Ya",
      occupation: "Director",
      medias: [
        {
          category: "Tel",
          items: [
            {
              content: "+959 095 176 178",
              to: "tel:+959095176178",
            }
          ]
        },
        {
          category: "Email",
          items: [
            {
              content: "sty@ichdepot.com",
              to: "mailto:sty@ichdepot.com",
            }
          ]
        }
      ]
    },
    {
      name: "Mr. Andy Naing",
      occupation: "Director",
      medias: [
        {
          category: "Tel",
          items: [
            {
              content: "+959 785 018 729",
              to: "tel:+959785018729",
            }
          ]
        },
        {
          category: "Email",
          items: [
            {
              content: "andy@ichdepot.com",
              to: "mailto:andy@ichdepot.com",
            }
          ]
        }
      ]
    },
    {
      name: "Mr. Kyaw Thu Ya",
      occupation: "Depot Manager",
      medias: [
        {
          category: "Tel",
          items: [
            {
              content: "+959 972 152 377",
              to: "tel:+959972152377",
            }
          ]
        },
        {
          category: "Email",
          items: [
            {
              content: "lkty@ichdepot.com",
              to: "mailto:lkty@ichdepot.com",
            }
          ]
        }
      ]
    },
    {
      name: "Mrs. May Phyo",
      occupation: "Accounts Department",
      medias: [
        {
          category: "Tel",
          items: [
            {
              content: "+959 445 588 892",
              to: "tel:+959445588892",
            }
          ]
        },
        {
          category: "Email",
          items: [
            {
              content: "mayphyo@ichdepot.com",
              to: "mailto:mayphyo@ichdepot.com",
            }
          ]
        }
      ]
    },
  ])
  const [services] = React.useState<ServiceItem[]>([
    {
      name: "Container Storage & LOLO",
      description: "Modern forklifts to handle all sorts of container types",
      image: "/image/acdsich-service-storage.webp",
    },
    {
      name: "Container Survey",
      description: "We have our own team to provide pre-trip inspection (PTI) for our customers",
      image: "/image/acdsich-service-survey.webp",
    },
    {
      name: "Container Repair",
      description: "We provide all sorts of container repair services",
      image: "/image/acdsich-service-repair.webp",
    },
    {
      name: "Container Washing",
      description: "We have our own washing equipment that can offer all kinds of washing requirements",
      image: "/image/acdsich-service-washing.webp",
    }
  ])
  const [tab, setTab] = React.useState({
    items: [
      {
        menu: "services",
        name: "Our Services",
        content: <>
          <div id="services" className="h-full md:min-h-[90vh] scroll-my-20 flex flex-col gap-4">
            <h2 className="text-3xl lg:!text-5xl font-medium">
              We provide a full suite of logistical support
            </h2>
            {
              services.length > 0 &&
              <Carousel2
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1280: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  }
                }}
                autoplay
                autoplayDelay={5000}
                navigable
                navPosition="tr"
                grabable>
                {{
                  items: services.map((service, i: number) => {
                    return {
                      children: <>
                        <React.Fragment key={`service-item-${i}`}>
                          <div className="w-full h-full">
                            <Image src={service.image} alt={service.name} className="object-cover object-center w-full h-96" width={4} height={3} />
                            <div className="flex flex-col gap-1 my-4">
                              <h3 className="text-lg md:!text-2xl font-medium">{service.name}</h3>
                              <p className="text-zinc-500">{service.description}</p>
                            </div>
                          </div>
                        </React.Fragment>
                      </>
                    }
                  })
                }}
              </Carousel2>
            }
          </div>
        </>,
        selected: true
      },
      {
        menu: "about",
        name: "About Us",
        content: <>
          <div id="about" className="h-full min-h-[90vh] scroll-my-20 grid grid-cols-12 gap-4 lg:gap-12">
            <div className="col-span-12 md:!col-span-7">
              <div className="flex flex-col gap-4">
                <h2 className="text-3xl lg:!text-5xl font-medium">
                  Who we are
                </h2>
                <Image src="/image/acdsich-logo.webp" alt="logo" className="w-48 h-18" />
                <div className="flex flex-col gap-2 text-zinc-500">
                  <p>ICH Inland Container Depot was incorporated in Yangon in 2017. The company provide Lift On/Lift Off facilities, pre-trip inspection (PTI), container storage, container survey, container repair and container washing services to our customers.</p>
                  <p>ICH Inland Container Depot operates on 10 acres of land and can hold up to 9,000 TEUs. We have invested in the most up-to-date forklift to provide the highest level of service. We operate 24/7 to provide the highest service to our customers.</p>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:!col-span-5">
              <div className="flex flex-col gap-4">
                <Card size="md">
                  <div className="flex flex-col gap-2 p-4">
                    <h3 className="text-acds-primary font-semibold">
                      Address
                    </h3>
                    <p>
                      Sittaung Road, Dagon Seikkan Dockyard,<br />
                      Dagon Myothit Seikkan Township, <br />
                      Yangon, Myanmar
                    </p>
                  </div>
                </Card>
                <Card size="md">
                  <div className="flex flex-col gap-1 p-4">
                    <h3 className="text-acds-primary font-semibold">
                      Contact
                    </h3>
                    <h4 className="text-black font-bold">
                      ICH Depot Group
                    </h4>
                    <div className="flex flex-col gap-2">
                      <ul className="list-none">
                        <li>
                          Email: &nbsp;
                          <Link to="mailto:ichdepot-grp@ichdepot.com" target="_blank" className="text-blue-600 underline" hideCrawl>
                            ichdepot-grp@ichdepot.com
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {
                      teams.length > 0 &&
                      <div className="my-2 flex flex-col">
                        <Button variant="primary" onClick={() => {
                          setModalTeam((prevState) => {
                            return {
                              ...prevState,
                              opened: true
                            }
                          })
                        }}>
                          Meet the Team
                        </Button>
                      </div>
                    }
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </>,
        selected: false
      }
    ]
  })
  const [enquiryForm, setEnquiryForm] = React.useState({
    loading: false,
    name: "",
    email: "",
    phone: "",
    message: "",
  })
  const [enquiryNotif, setEnquiryNotif] = React.useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  })
  const [enquiryValidator] = useValidator({
    name: Validator.string().required().min(3).max(256),
    email: Validator.string().required().email().min(3).max(256),
    phone: Validator.string().required().phone("SG").min(5).max(17).trim(),
    message: Validator.string().required().min(3).max(2048),
  })

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target
    setEnquiryForm({ ...enquiryForm, [name]: value })
  }

  async function submitEnquiry(form: typeof enquiryForm & { verificationToken: string }) {
    toast.info(
      "Submiting your enquiry, please wait a moment",
      { className: "toast-mesasge" }
    )
    setEnquiryForm((prevState) => {
      return {
        ...prevState,
        loading: true,
      }
    })
    const sendEnquiry = await contactService.SendEnquiry(form)
    setEnquiryForm((prevState) => {
      return {
        ...prevState,
        loading: false,
      }
    })
    if (sendEnquiry.error) {
      console.error("An error occurred while submitting the form", sendEnquiry.error)
      toast.error(
        `Failed send enquiry: ${sendEnquiry.error.message}`,
        { className: "toast-mesasge" }
      )
      return
    }

    setEnquiryForm((prevState) => {
      return {
        ...prevState,
        name: "",
        email: "",
        phone: "",
        message: "",
      }
    })
    toast.success(
      "Enquiry Submitted Successfully. Our team will get back to you shortly",
      { className: "toast-mesasge" }
    )
  }

  const handleEnquirySubmit = (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()

      setEnquiryNotif({
        name: "",
        email: "",
        phone: "",
        message: "",
      })

      const validateRes = enquiryValidator.validate({
        name: enquiryForm.name,
        email: enquiryForm.email,
        phone: enquiryForm.phone,
        message: enquiryForm.message,
      })
      if (validateRes.error) {
        const err = validateRes.error

        if (err instanceof Validator.ValidationError) {
          setEnquiryNotif((prevState) => {
            return {
              ...prevState,
              [err.path]: err.errors.join("\n")
            }
          })
        } else {
          console.warn("unexpected error: ", err)
        }

        return
      }

      // @ts-ignore
      if (!grecaptcha) {
        toast.error(
          "Failed initialized recaptcha",
          { className: "toast-mesasge" }
        )
        return
      }

      // @ts-ignore
      grecaptcha.ready(async () => {
        // @ts-ignore
        const token = await grecaptcha.execute(process.env.REACT_APP_RECAPTCHAV3_SITE, { action: "submit" })
        if (!token) {
          toast.error(
            "Failed verify reCAPTCHA, please try again.",
            { className: "toast-mesasge" }
          )
          return
        }

        submitEnquiry({
          ...enquiryForm,
          verificationToken: token
        })
      })
    } catch (error: any) {
      console.error("Failed submit enquiry", error)
      toast.error(
        "Failed submit enquiry",
        { className: "toast-mesasge" }
      )
    }
  }

  const location = useLocation()
  useAutoPosition()
  useHashFragment()
  useRecaptcha({
    siteId: process.env.REACT_APP_RECAPTCHAV3_SITE
  })

  React.useEffect(() => {
    if (!location || !location.hash) {
      return
    }

    const menus = new Set(["services", "about"])
    const hash = location.hash.slice(1)
    if (!menus.has(hash)) {
      return
    }

    const element = document.getElementById("info")
    element.scrollIntoView({
      behavior: "smooth",
      block: "start"
    })

    setTab((prevState) => {
      return {
        ...prevState,
        items: prevState.items.map((item) => {
          return {
            ...item,
            selected: (item.menu === hash)
          }
        })
      }
    })
  }, [location])

  return (
    <>
      <Meta>
        {{
          title: "Home",
          description: "ICH Inland Container Depot was incorporated in Yangon in 2017. The company provide Lift On/Lift Off facilities, pre-trip inspection (PTI), container storage, container survey, container repair and container washing services to our customers. ICH Inland Container Depot operates on 10 acres of land and can hold up to 9,000 TEUs. We have invested in the most up-to-date forklift to provide the highest level of service. We operate 24/7 to provide the highest service to our customers."
        }}
      </Meta>

      {/* @note: do not remove, issue with react-snap, check NotFound page */}
      <div></div>

      <div className="w-full h-screen bg-cover bg-center" style={{ backgroundImage: `url(${"/image/acdsich-hero.webp"})` }}>
        <Header.AlliedDepot
          logoUrl="/"
          logoImage="/image/acdsich-logo.webp"
          linkItems={[
            {
              content: "Services",
              to: "/#services"
            },
            {
              content: "About",
              to: "/#about"
            }
          ]}
          menuItems={[
            {
              content: "Email Web Login",
              to: "https://mail.google.com",
              target: "_blank"
            },
            {
              content: "Login",
              to: "/login"
            },
          ]} />
        <div className="w-full h-full flex flex-col justify-center items-center gap-6">
          <h1 className="text-center text-white text-3xl lg:!text-5xl font-medium">
            To innovate <br /> and modernize
          </h1>
          <div className="flex justify-center items-center">
            <ButtonLink to="/#info" variant="primary" size="lg" appendClassNames="min-w-48 lg:!min-w-64 text-center">
              Get Started
            </ButtonLink>
          </div>
        </div>
      </div>

      <Container size="full">
        {
          tab.items.length > 0 &&
          <section id="info" className="flex flex-col justify-center items-center bg-white px-2 py-8 w-full h-full lg:!min-h-screen">
            <div className="grid grid-cols-12 gap-4 w-full">
              <div className="col-span-12 lg:!col-span-3 2xl:!col-span-2">
                <div className="w-full h-full lg:!px-12 border-transparent lg:!border-r lg:!border-r-gray-400">
                  <div className="flex flex-row lg:!flex-col gap-4">
                    {
                      tab.items.map((item, i: number) => {
                        return (
                          <button key={`tab-menu-${i}`} aria-label={item.name} className={
                            "lg:!w-full p-2 text-lg hover:text-acds-primary font-bold border-1 hover:border-acds-primary " +
                            (item.selected ? "border-acds-primary text-acds-primary" : "border-transparent text-black")
                          } onClick={() => {
                            setTab((prevState) => {
                              return {
                                ...prevState,
                                items: prevState.items.map((item, j: number) => {
                                  return {
                                    ...item,
                                    selected: (i === j),
                                  }
                                })
                              }
                            })
                          }}>
                            {item.name}
                          </button>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              <div className="col-span-12 lg:!col-span-9 2xl:!col-span-10">
                <div className="w-full h-full lg:!px-12">
                  {
                    tab.items
                      .filter((item) => item.selected)
                      .map((item, i: number) => {
                        return (
                          <React.Fragment key={`tab-item-${i}`}>
                            {item.content}
                          </React.Fragment>
                        )
                      })
                  }
                </div>
              </div>
            </div>
          </section>
        }

        <section id="contact" className="flex flex-col justify-center items-center bg-white px-2 py-8 w-full h-full lg:!min-h-screen">
          <div className="grid grid-cols-12 w-full sm:!w-4/5 md:!w-full 2xl:!w-2/3 gap-8">
            <div className="col-span-12 md:!col-span-6 lg:!col-span-5 xl:!col-span-5">
              <div className="mx-auto max-w-md">
                <div className="flex flex-col gap-2 my-6">
                  <span className="text-zinc-500">Interested?</span>
                  <h2 className="text-xl md:!text-4xl font-medium">Contact Us</h2>
                </div>
                <Loader lazy>
                  <form onSubmit={handleEnquirySubmit}>
                    <div className="flex flex-col gap-3">
                      <Field>
                        <label htmlFor="name">
                          Name <span className="text-red-600">*</span>
                        </label>
                        <InputText
                          id="name"
                          name="name"
                          placeholder="Name"
                          value={enquiryForm.name}
                          onChange={handleInputChange}
                          disabled={enquiryForm.loading}
                          required />
                        <FieldNotif>{enquiryNotif.name}</FieldNotif>
                      </Field>
                      <Field>
                        <label htmlFor="email">
                          Email address <span className="text-red-600">*</span>
                        </label>
                        <InputEmail
                          id="email"
                          name="email"
                          placeholder="Email address"
                          value={enquiryForm.email}
                          onChange={handleInputChange}
                          disabled={enquiryForm.loading}
                          required />
                        <FieldNotif>{enquiryNotif.email}</FieldNotif>
                      </Field>
                      <Field>
                        <label htmlFor="phone">
                          Phone number <span className="text-red-600">*</span>
                        </label>
                        <InputPhone
                          id="phone"
                          name="phone"
                          placeholder="Phone number"
                          value={enquiryForm.phone}
                          onUpdate={(m) => {
                            setEnquiryForm((prevState) => {
                              return {
                                ...prevState,
                                phone: m.phone_number
                              }
                            })
                          }}
                          disabled={enquiryForm.loading}
                          required />
                        <FieldNotif>{enquiryNotif.phone}</FieldNotif>
                      </Field>
                      <Field>
                        <label htmlFor="message">
                          Message <span className="text-red-600">*</span>
                        </label>
                        <Textarea
                          id="message"
                          name="message"
                          placeholder="Message"
                          value={enquiryForm.message}
                          onChange={handleInputChange}
                          disabled={enquiryForm.loading}
                          required>
                        </Textarea>
                        <FieldNotif>{enquiryNotif.message}</FieldNotif>
                      </Field>
                      <Field>
                        <div className="g-recaptcha"
                          data-sitekey={process.env.REACT_APP_RECAPTCHAV3_SITE}
                          data-size="invisible"
                          data-action="submit">
                        </div>
                      </Field>
                      <Button type="submit" size="lg" disabled={enquiryForm.loading}>
                        Send a Message
                      </Button>
                    </div>
                  </form>
                </Loader>
              </div>
            </div>
            <div className="col-span-12 md:!col-span-6 lg:!col-span-7 xl:!col-span-7">
              <div className="h-[300px] md:h-[400px] lg:h-[700px] py-4">
                <Loader lazy>
                  <MapViewer provider="gmap_embed" query={"ICH Depot Sit Taung Road, Yangon, Myanmar (Burma)"} />
                </Loader>
              </div>
            </div>
          </div>
        </section>
      </Container>

      <Modal
        portalId="overlay-root"
        size="lg"
        opened={modalTeam.opened}
        onClose={() => {
          setModalTeam((prevState) => {
            return {
              ...prevState,
              opened: false
            }
          })
        }}
        lostable
        closeable>
        {{
          content: (
            <>
              <div className="flex flex-col justify-center items-center">
                <h2 className="text-lg">
                  Meet the Team
                </h2>
                <h1 className="text-3xl md:!text-4xl font-semibold">
                  Hello!
                </h1>
              </div>
              <div className="max-h-96 overflow-y-auto">
                <div className="grid grid-cols-12 gap-4 my-4">
                  {
                    teams.map((team, i: number) => {
                      return (
                        <React.Fragment key={`team-item-${i}`}>
                          <div className="col-span-12 md:!col-span-6">
                            <Card size="md">
                              <div className="flex flex-col gap-1 p-2">
                                <h3 className="text-acds-primary font-semibold">
                                  Contact
                                </h3>
                                <span className="font-bold">{team.name}</span>
                                <span>{team.occupation}</span>
                                {
                                  team.medias.length > 0 &&
                                  <ul className="list-none">
                                    {
                                      team.medias.map((media, j: number) => {
                                        return (
                                          <React.Fragment key={`media-item-${i}-${j}`}>
                                            <li>
                                              {`${media.category}: `}
                                              {
                                                media.items.map((link, k: number) => {
                                                  return (
                                                    <React.Fragment key={`link-item-${i}-${j}-${k}`}>
                                                      <Link to={link.to} target="_blank" className="text-blue-600 underline" hideCrawl>
                                                        {link.content}
                                                      </Link>
                                                    </React.Fragment>
                                                  )
                                                })
                                              }

                                            </li>
                                          </React.Fragment>
                                        )
                                      })
                                    }
                                  </ul>
                                }
                              </div>
                            </Card>
                          </div>
                        </React.Fragment>
                      )
                    })
                  }
                </div>
              </div>
            </>
          )
        }}
      </Modal>
    </>
  )
}